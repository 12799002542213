@tailwind base;
@tailwind components;
@tailwind utilities;

/*  Turn Off Number Input Spinners */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #374151;
}
